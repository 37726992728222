import React, { useEffect, useState } from "react";
import { equals } from "ramda";
import Button from "@/components/shared/Button";
import usePrevious from "@/hooks/usePrevious";
import AccordionItem from "./Accordion/AccordionItem";

export interface AccordionItemData {
  title: React.ReactNode;
  content: React.ReactNode;
}

export interface AccordionProps {
  items: AccordionItemData[];
  className?: string;
  borderColor?: string;
  itemLimit?: {
    minimum: number;
    button: {
      moreItemsText: string;
      lessItemsText: string;
      className?: string;
    };
  };
  activeTitleClass?: string;
  multiExpandEnabled?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  className,
  itemLimit,
  borderColor = "border-grey-asphalt",
  activeTitleClass = "text-secondary-burgundy",
  multiExpandEnabled = true,
}) => {
  const [activeIndexes, setActiveIndexes] = useState<number[] | null>(null);
  const [showAll, setShowAll] = useState(false);
  const prevItems = usePrevious(items);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (!activeIndexes || equals(prevItems, items)) return;
    setActiveIndexes(null);
  }, [items]);

  return (
    <ul className={`flex flex-col ${className ?? ""}`}>
      {items.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            index={index}
            item={item}
            activeIndexes={activeIndexes}
            setActiveIndexes={setActiveIndexes}
            showItem={!itemLimit || index < itemLimit.minimum || showAll}
            borderColor={borderColor}
            activeTitleClass={activeTitleClass}
            multiExpandEnabled={multiExpandEnabled}
          />
        );
      })}

      {itemLimit && itemLimit.minimum < items.length && (
        <div className="tablet:pt-12 pt-8">
          <Button onClick={toggleShowAll} type="button" variant="secondary">
            {showAll ? itemLimit.button.lessItemsText : itemLimit.button.moreItemsText}
          </Button>
        </div>
      )}
    </ul>
  );
};

export default Accordion;
