import React, { useRef } from "react";
import ChevronDown from "@/components/Icons/ChevronDown";
import { AccordionItemData } from "../Accordion";

interface AccordionItemProps {
  index: number;
  activeIndexes: number[] | null;
  setActiveIndexes: (activeIndexes: number[] | null) => void;
  item: AccordionItemData;
  showItem: boolean;
  borderColor: string;
  activeTitleClass: string;
  multiExpandEnabled?: boolean;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  index,
  activeIndexes,
  setActiveIndexes,
  item: { title, content },
  showItem,
  borderColor,
  activeTitleClass,
  multiExpandEnabled,
}) => {
  const titleRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const showContent = activeIndexes?.includes(index) && showItem;
  const titleHeight = showItem ? titleRef.current?.scrollHeight ?? "auto" : 0;
  const contentHeight = showContent ? contentRef.current?.scrollHeight ?? "auto" : 0;

  const onClickHandler = () => {
    if (showContent) {
      setActiveIndexes(activeIndexes?.filter((item) => item !== index) ?? null);
    } else if (multiExpandEnabled) {
      setActiveIndexes(activeIndexes ? [...activeIndexes, index] : [index]);
    } else {
      setActiveIndexes([index]);
    }
  };

  return (
    <li
      key={index}
      className={`group flex flex-col ${showItem ? "border-b" : "border-0"} ${borderColor}`}
    >
      <button
        ref={titleRef}
        className={`w-full overflow-hidden text-left transition-[height] duration-75 ease-in-out ${
          showContent ? activeTitleClass : "text-inherit"
        }`}
        onClick={onClickHandler}
        disabled={!showItem}
        style={{ height: titleHeight }}
        data-testid="accordion-item"
      >
        <div className="flex cursor-pointer select-none items-start justify-between py-4 group-first:pt-0">
          <div
            className="text-label-sm tablet:text-label flex flex-1 font-sans font-semibold tracking-wider"
            data-testid="accordion-title"
            data-gtm-id="faq"
          >
            {title}
          </div>
          <ChevronDown
            data-gtm-id="faq"
            className={`transition-1 mx-3 mt-0.5 size-6 shrink-0 duration-100 ${
              showContent ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-200 ease-in-out ${
          showContent ? "visible" : "invisible"
        }`}
        style={{
          height: contentHeight,
        }}
        data-testid="accordion-content"
      >
        <div className="text-p-sm tablet:text-p mb-6 font-serif tracking-wider">{content}</div>
      </div>
    </li>
  );
};

export default AccordionItem;
