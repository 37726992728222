import Link from "next/link";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { parseQuoteDark, parseQuote, parseText } from "@/utilities/htmlParser";
import QuoteImage from "@/components/blocks/CustomerExperienceEntryBlock/QuoteImage";
import { Grid } from "../shared/Grid";
import CustomerStoriesButton from "./CustomerExperienceEntryBlock/CustomerStoriesButton";

export type CustomerExperienceEntryBlockProps =
  GetDynamicContentItemProps<"CustomerExperienceEntryBlock">;

const CustomerExperienceEntryBlock: React.FC<CustomerExperienceEntryBlockProps> = ({
  darkthemeenabled,
  title,
  body,
  ctabuttontext,
  ctabuttontarget,
  customquote,
  customer_story,
  className,
}) => {
  const { _slug, caption, image } = customer_story?.[0] ?? {};
  const quote = customquote ?? customer_story?.[0]?.quote ?? "";
  const customerImage = image?.[0];
  const colors = darkthemeenabled ? "gradient-2 text-white" : "bg-grey-light text-black";
  const panelColor = darkthemeenabled ? "bg-primary-black" : "bg-white";

  const replaceWithCurlyMarks = (text: string) => text.replace(/"([^"]*)"/g, "“$1”");

  return (
    <div className={`tablet:py-20 relative w-full py-10 ${colors} ${className ?? ""}`}>
      <Grid>
        <div className="tablet:col-span-5 tablet:col-start-2 tablet:mt-20 col-span-4 flex flex-col">
          <h2 className="text-h3-sm tablet:text-h4 font-sans font-bold tracking-wider">{title}</h2>
          <div className="text-p-sm tablet:text-p mt-6 font-serif tracking-wider">
            {parseText(body ?? "")}
          </div>
          <CustomerStoriesButton
            text={ctabuttontext}
            target={ctabuttontarget}
            darkThemeEnabled={darkthemeenabled}
            className="tablet:block hidden"
          />
        </div>
        <Link
          href={_slug ?? "/klantverhalen"}
          className={`tablet:col-start-8 tablet:row-start-1 tablet:mt-0 group col-span-4 row-span-2 mt-8 overflow-hidden ${panelColor}`}
        >
          {customerImage?.default && (
            <QuoteImage
              image={customerImage.default}
              alt={customerImage.description ?? "customer image"}
            />
          )}
          {caption && quote && (
            <div className="tablet:mt-10 tablet:px-12 tablet:pb-14 mt-8 px-6 pb-10">
              <div className="text-p-xs tablet:text-p font-serif tracking-wider">{caption}</div>
              <div className="text-h4-sm tablet:mt-5 tablet:text-h3-sm mt-3.5 font-bold tracking-wider">
                {darkthemeenabled
                  ? parseQuoteDark(replaceWithCurlyMarks(quote))
                  : parseQuote(replaceWithCurlyMarks(quote))}
              </div>
            </div>
          )}
        </Link>
        <CustomerStoriesButton
          text={ctabuttontext}
          target={ctabuttontarget}
          darkThemeEnabled={darkthemeenabled}
          className="tablet:hidden block"
        />
      </Grid>
    </div>
  );
};

export default CustomerExperienceEntryBlock;
